import { State, Selector, Action, StateContext } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';

import { StartLoading, StopLoading, SetSystemType } from '../actions/catalogue.actions';
import { SystemTypes } from '../constants/SystemTypes';
import { tap } from 'rxjs/operators';

export interface CatalogueModel {
	loading: boolean;
	systemType: SystemTypes;
	currentItem: any;
}

const defaults: CatalogueModel = {
	loading: false,
	systemType: SystemTypes.SPLIT,
	currentItem: null
};

@State({
	name: 'catalogue',
	defaults
})
export class CatalogueState {
	@Selector()
	static loading({ loading }: CatalogueModel) {
		return loading;
	}

	@Selector()
	static systemType({ systemType }: CatalogueModel) {
		return systemType;
	}

	constructor(private http: HttpClient) {}

	@Action(StartLoading)
	startLoading({ patchState }: StateContext<CatalogueModel>) {
		patchState({
			loading: true
		});
	}

	@Action(StopLoading)
	stopLoading({ patchState }: StateContext<CatalogueModel>) {
		patchState({
			loading: false
		});
	}

	@Action(SetSystemType)
	setSystemType({ patchState }: StateContext<CatalogueModel>, { payload: { systemType } }: SetSystemType) {
		patchState({
			systemType: systemType
		});
	}
}
