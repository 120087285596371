'use strict';
import { ExtTypes } from './ExtTypes';

export class HttpRequestPath {
	static readonly GetQuoteById = '/api/projects/get-quote-by-id';

	static readonly GetQuotes = '/api/projects/get-quotes';

	static readonly GetGroupsQuotes = '/api/projects/get-groups-quotes';

	static readonly UpdateQuote = '/api/projects/update-quote';

	static readonly AcceptQuote = '/api/projects/accept-quote';

	static readonly RenewQuote = '/api/projects/renew-quote';

	static readonly CancelQuote = '/api/projects/cancel-quotes';

	static readonly PrepayQuotes = '/api/payment/quote-prepay';

	static readonly GetFile = '/api/FileStorage/file';

	static readonly UpdateConfig = '/api/projects/updateProjectsConfig';

	static readonly GetConfigs = '/api/projects/getProjectsConfig';

	static readonly GetAcceptedQuotes = '/api/projects/get-quotes-accepted';

	static readonly GetQuotesAwaiting = '/api/projects/get-quotes-awaiting';

	static readonly GetTemplates = '/api/utils/get-templates';

	static readonly GetPdf = '/api/Site/pdf/fa.pdf';

	static readonly GetProject = '/api/Projects/getProject';

	static readonly GetProjects = '/api/Projects/getProjects';

	static readonly SendProjectResponse = '/api/Projects/sendProjectResponse';

	static readonly CreateAccount = '/api/Auth/createAccount';

	static readonly GetUsers = '/api/Auth/getUsers';

	static readonly AuthLogin = '/api/Auth/login';

	static readonly GetDicionaries = '/api/Dictionary/getdictionaries';

	// TODO:: Fix this;
	static readonly GetSuppliers = '/api/Repairs/getsuppliers';

	static readonly DepositReceived = '/api/projects/deposit-received';

	static readonly GetItem = (extType: ExtTypes) => `/api/Dictionary/${extType}/getitem`;

	static readonly EditItem = (extType: ExtTypes) => `/api/Dictionary/${extType}/edititem`;

	static readonly AddItem = (extType: ExtTypes) => `/api/Dictionary/${extType}/additem`;

	static readonly DeleteItem = (extType: ExtTypes) => `/api/Dictionary/${extType}/deleteitem`;

	static readonly GetDictionary = (extType: ExtTypes, systemType: string) => `/api/Dictionary/${extType}/${systemType}/getdictionary`;
}
