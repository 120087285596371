import { AccessData } from '../auth/access-data';

export enum AuthActionTypes {
	Login = '[Authentication] Login',
	LoginSucces = '[Authentication] LoginSucces',
	SetUserToken = '[Authentication] SetUserToken'
}

export class Login {
	static readonly type = AuthActionTypes.Login;

	constructor(public payload: { username: string; password: string }) {}
}

export class LoginSucces {
	static readonly type = AuthActionTypes.LoginSucces;

	constructor(public payload: { data: AccessData }) {}
}

export class SetUserToken {
	static readonly type = AuthActionTypes.SetUserToken;

	constructor(public payload: { token: string }) {}
}

export class Reset {
	static readonly type = '[Authentication] Reset';
}
