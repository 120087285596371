import { SystemTypes } from '@app/core/constants/SystemTypes';
import { ExtTypes } from '../constants/ExtTypes';
import { IItem } from '../interfaces/IItem';

export class StartLoading {
	static readonly type = '[Catalogue] StartLoading';
}

export class StopLoading {
	static readonly type = '[Catalogue] StopLoading';
}

export class SetSystemType {
	static readonly type = '[Catalogue] SetSystemType';

	constructor(public payload: { systemType: SystemTypes } ) {}
}
