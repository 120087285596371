import { Action, State, StateContext, Selector } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { tap, finalize } from 'rxjs/operators';
import produce from 'immer';

import { Login, Reset } from '../actions/auth.actions';
import { AuthenticationService } from '../auth/authentication.service';

export interface AuthenticationStateModel {
	token: string;
	time: number;
}

const defaults: AuthenticationStateModel = {
	token: null,
	time: null
};

@State<AuthenticationStateModel>({
	name: 'auth',
	defaults
})
export class AuthenticationState {
	@Selector()
	static token({ token }: AuthenticationStateModel) {
		return token;
	}

	@Selector()
	static time({ time }) {
		return time;
	}

	constructor(private authService: AuthenticationService) {}

	@Action(Login)
	public login({ getState, setState, dispatch}: StateContext<AuthenticationStateModel>, { payload }: Login) {
		return this.authService.login(payload).pipe(
			tap(res => {
				setState(produce(getState(), draft => {
					draft.token = res.accessToken;
					draft.time = Date.now();
				}));
			}),
			finalize(() => dispatch(new Navigate(['/'])))
		);
	}

	@Action(Reset)
	public reset({ setState }: StateContext<AuthenticationStateModel>) {
		setState(defaults);
	}
}
