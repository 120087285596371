import {Injectable} from '@angular/core';
import {HttpParams, HttpHeaders} from '@angular/common/http';


@Injectable()
export class HttpUtilsService {
	getHTTPHeaders(): HttpHeaders {
		const result = new HttpHeaders();
		result.set('Content-Type', 'application/json');
		return result;
	}

	getFindHTTPParams(queryParams): HttpParams {
		const params = new HttpParams()
			.set('lastNamefilter', queryParams.filter)
			.set('sortOrder', queryParams.sortOrder)
			.set('sortField', queryParams.sortField)
			.set('pageNumber', queryParams.pageNumber.toString())
			.set('pageSize', queryParams.pageSize.toString());

		return params;
	}

	getHTTPHeader() {
		return {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'CLIENT-ID': 'fa', 'ZUMO-API-VERSION': '2.0.2',
				'apiKey': '2002C6243B27753D0477B96DCB655A5B42BF1C990A4226A6F263AB98AB339363'
			})
		};
	}
}
