// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
					{
						title: 'Dashboard',
						desc: 'Some description goes here',
						icon: 'flaticon-line-graph',
						root: true,
						page: '/',
						translate: 'MENU.DASHBOARD'
					},
					{
						title: 'Quotes',
						desc: 'quotes page',
						root: true,
						icon: 'flaticon-interface-11',
						page: '/quotes'
					},
					{
						title: 'Catalogue',
						root: true,
						icon: 'flaticon-interface-7',
						bullet: 'dot',
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'Materials',
									page: '/materials'
								},
								{
									title: 'Brands',
									page: '/brands'
								},
								{
									title: 'Categories',
									page: '/categories'
								},
								{
									title: 'Addons',
									page: '/addons'
								},
								{
									title: 'Units',
									page: '/units'
								}
							]
						},
					},
					{
						title: 'Repairs',
						root: true,
						icon: 'flaticon-open-box',
						bullet: 'dot',
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'Suppliers',
									page: '/repairs/suppliers'
								},
								{
									title: 'Materials And Parts',
									page: '/repairs/materials-and-parts'
								},
								{
									title: 'Material Installation Cost',
									page: '/repairs/material-inst'
								},
								{
									title: 'Parts Categories',
									page: '/repairs/parts-categories'
								},
								{
									title: 'Unit Types',
									page: '/repairs/unit-types'
								}
							]
						},
					},
					{
						title: 'Settings',
						root: true,
						icon: 'flaticon-cogwheel-1',
						bullet: 'dot',
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'Users',
									page: '/users'
								},
								{
									title: 'Configuration',
									page: '/configuration'
								},
								{
									title: 'Templates',
									page: '/templates'
								},
							]
						}
					}
				]
			},
			aside: {
				self: {},
				items: []
			}
		};
	}
}
