import { ISystemBrand } from './../interfaces/ISystemBrand';
import { Injectable } from '@angular/core';
import { KuHttpService } from './ku-http-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISystemUnit, IOptional } from '../interfaces/ISystemUnit';

export interface ISystemUnits {
	items: ISystemUnit[];
}


@Injectable()
export class KuService {

	constructor(private kuService: KuHttpService) {}

	public getSeriesList(systemType: string): Observable<ISystemUnit[]> {
		return this.kuService
		.operate<ISystemUnits>('/api/Dictionary/Series/' + systemType + '/GetDictionary').pipe(map(res => res.data.items));
	}

	public getBrand(systemType: string): Observable<ISystemBrand[]> {
		return this.kuService
		.operate<ISystemUnits>('/api/Dictionary/Brand/' + systemType + '/GetDictionary').pipe(map(res => res.data.items));
	}
	// public createFeeForServiceItem(data: DtoCreateFeeForServiceItem): Observable<DtoCreateFeeForServiceItem> {
	// 	return this.kuService
	// 	.operateWithBody<DtoCreateFeeForServiceItem, DtoCreateFeeForServiceItem>(
	// 		data,
	// 		KuConfig.CreateFeeForServiceItem
	// 	)
	// 	.pipe(map(res => res.data));
	// }
}

