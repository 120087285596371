import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngxs/store';

@Component({
  selector: 'm-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss']
})
export class RemoveModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { action: any }, private store: Store) { }

  public removeItem(): void {
    this.store.dispatch(this.data.action);
  }
}
