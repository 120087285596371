import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { environment } from '@env/environment';
import { AuthenticationState } from '../state/auth.state';

/**
 * Add authorization token to all Http requests
 */
@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.headers.has('X-ZUMO-AUTH') && request.url.startsWith(environment.serverUrl)) {

			const token = this.store.selectSnapshot(AuthenticationState.token);
			if (token) {
				request = request.clone({
					setHeaders: {
						'X-ZUMO-AUTH': token
					}
				});

			}
            return next.handle(request);
        }
        return next.handle(request);
    }
}
